/** @format */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.makeStyles-link-7.MuiButton-outlinedPrimary {
  color: #2a5014;
  border: 1px solid rgb(42 80 20);
}

.MuiTableCell-root {
  display: table-cell;
  padding: 5px !important;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.MuiAccordionSummary-content.Mui-expanded {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.MuiAccordionSummary-content {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  font-size: 13px;
}
.display-spill-link,
.edit-spill-link {
  text-decoration: none;
  color: #2f7d32;
}
.customTab .Mui-selected{font-weight: 600;color: #2F7D32;}
.customTab .MuiTabs-indicator{background: #2F7D32!important; opacity: 0.7 !important;}

/* custom accordion */
.custom-accordion{margin-bottom: 12px; border-radius: 8px !important;}
.custom-accordion .MuiAccordionSummary-root{ border-radius: 10px;    padding: 0px 16px; background-color: #dedcdc;  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);}
.custom-accordion .MuiAccordion-root:before {background-color: #fff;}
.custom-accordion .MuiAccordionDetails-root{padding: 20px;}
.custom-accordion .MuiAccordionSummary-root.Mui-expanded{min-height: 48px ;}
.custom-accordion .MuiAccordionSummary-content, .custom-accordion .MuiAccordionSummary-content.Mui-expanded{justify-content: start; margin: 0;}
.custom-accordion .MuiAccordionSummary-content p{align-self: center; margin-left: 6px;}
.custom-accordion .facilities-icon {padding-right: 4px;}
.custom-accordion .facilities-icon path{fill: rgb(199 169 13);}
.custom-radio.MuiFormGroup-root{flex-direction: row !important;}
.custom-accordion .MuiCheckbox-colorSecondary.Mui-checked {
  color: #2F7D32 !important;
  fill:  #2F7D32 !important;
}
/* table */
.customTable td{padding: 12px !important;}
.customTable thead th{font-weight: 700;background-color: #dedcdc;  color: #2F7D32 ; font-size: 16px; padding: 12px !important;}
/* tabdetail  */
.tabDetail-format h3{margin-bottom: 6px; color: #2F7D32 ; font-size: 15px; margin-top: 6px; text-transform: capitalize;}
.tabDetail-format p , .tabDetail-format strong{font-size: 13px; margin:6px 0 !important;}

.CustomScrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: none;
	background-color: #F5F5F5 !important;
}

.CustomScrollbar::-webkit-scrollbar
{
	width: 5px!important;
	background-color: #F5F5F5!important;
  border-radius: 2px;
}

.CustomScrollbar::-webkit-scrollbar-thumb
{
	background-color: #9e9e9e!important;
	border: none!important;
  border-radius: 6px;
}
.delete-icon path{color:#f83636;}
.hidden-accordion{display: none !important;}
.hidden {
  display: none;
}
.show{display: block;}
.form-error {color: #f83636;
  margin:2px 0 0 0;
  text-align: left;
  font-size: 10px;}

  .errorFacilityMessage{
    background-color: rgb(255 88 88) !important;
    color: white !important;
    font-weight: 600 !important;
  }
  .errorFacilityMessage .MuiAlert-icon{display: none!important;}


  /* for error message */
  .error_snackbar{max-width: 350px;}
  .error_snackbar .MuiSnackbarContent-root{
    background-color:rgb(253 48 48) !important;
    color: white !important;
    font-weight: 500 !important;
    min-width: auto;
    text-align: left;
    font-size: 13px;
    max-width: 350px;
  }
  .error_snackbar .MuiAlert-message{
    white-space: pre-line;
    text-align: left;
    align-self: center;
    padding: 0  ;
  }
  .error_snackbar .MuiAlert-icon{align-self: center;}

  .area-covered-modal .MuiDialogContent-root{padding:30px 60px;}

  .area-covered-content{display: flex;}
  .area-covered-content p {margin: 0; font-size: 13px; }
  .area-covered-content p strong{color: #2f7d32;}
  .area-covered-content .area-content-col p{font-weight: 600; color: #666666;}

  .area-covered-modal-header{   display: flex;justify-content: center;position: relative;background: #2F7D32;color: white;padding: 8px 0;}
  .area-covered-modal-header h2{color: #fff;}
  .area-covered-modal-header .close-icon{align-self: center; position: absolute; right: 4px; cursor: pointer;}
    .area-covered-modal-header .close-icon svg{fill: #fff;} 
  .area-covered-modal-header .MuiDialogTitle-root{padding: 0; color: #2a5014;}
  .area-covered-modal .MuiDialog-paper {padding: 0px;}
  .area-covered-radio-btn{padding-top: 20px !important;}
  .area-covered-radio-btn .MuiFormGroup-root{flex-direction: row;gap: 45px;align-items: center; }
  .area-covered-radio-btn .MuiRadio-colorSecondary.Mui-checked{color: #2F7D32;}
  .rectangle{height: 100px ; width: 200px; border: 2px solid #2F7D32; margin:10px 0;}
  .circle{height: 120px ; width: 120px; border: 2px solid #2F7D32; border-radius: 50%; margin: 0;}
  .footprint-form .MuiRadio-colorSecondary.Mui-checked{color: #2F7D32;}
  .calculation-row{display: flex; flex-wrap: wrap; gap: 20px; margin: 12px;}
  .sqft-txt{color: #858282; font-size: 12px; padding-top: 22px;display: inline-block;
    vertical-align: bottom;}
  .footprint-form .MuiInputBase-root.Mui-disabled {  color: rgb(0 0 0 / 54%); }
  .samplefile a{ color: #fff;
    background-color: #2F7D32;     padding: 6px 16px; text-decoration: none;
    font-size:14px;
    min-width: 64px; letter-spacing: 0.02857em;
    border-radius: 8px;display: inline-block;}
  .uploaded-file{font-size: 12px; margin:10px 0 0 0; color: #282c34; text-align: right;}
  /* team terminal acordion */
  .Team_Acoordion{ margin: 12px 0;}
  .Team_Acoordion .MuiAccordionSummary-root{  background-color: rgba(47, 125, 50, 0.76) !important; min-height: 38px;}
  .Team_Acoordion .MuiAccordionSummary-content{margin: 8px 0;}
  .Team_Acoordion .MuiAccordionSummary-content.Mui-expanded{margin: 8px 0;}
  .Team_Acoordion .MuiAccordionSummary-root.Mui-expanded{min-height: 38px;}
  .Team_Acoordion.MuiAccordion-root.Mui-expanded:first-child{margin-top: 12px !important;}
  .Team_Acoordion .MuiAccordionSummary-content p{color: #fff !important; font-weight: bold !important; font-size: 0.9rem;}
  .Team_Acoordion .MuiSvgIcon-root{fill: white;}
  .Team_Acoordion .MuiAccordionDetails-root{padding:10px 0;}
  .Team_Acoordion .MuiTableContainer-root{border-radius: 0 ; box-shadow: none;}
  .team-table thead th{ color: #2F7D32; font-size: 14px; font-weight: 700;padding: 10px !important;position: sticky;  top: 0; background: #fff;}
  .team-table tbody th{ color: #595959; font-size: 13px; font-weight: 600;padding: 10px !important; text-align: left; min-width: fit-content;}
  .team-table tbody td{ color: #595959; font-size: 13px; padding: 10px !important;text-align: left; min-width: fit-content;}
  
  .terminal-table{max-height: 320px; overflow-y: auto; overflow-x: auto;}
  .terminal-table::-webkit-scrollbar-track
  {
    -webkit-box-shadow: none !important;
    background-color: #F5F5F5 !important;
  }
  
  .terminal-table::-webkit-scrollbar
  {
    width:6px!important;
    background-color: #F5F5F5!important;
  }
  
  .terminal-table::-webkit-scrollbar-thumb
  {
    background-color: #929292!important;
    border: 1px solid #929292!important;
    border-radius: 5px;
  }
  .team-terminal-dialog .MuiDialogTitle-root{min-width: 300px; background-color: #2F7D32; padding: 10px;}
  .team-terminal-dialog .MuiDialogTitle-root h2{color: #fff;}
  .team-terminal-dialog table th {color: #2a5014; text-align: left; min-width: 120px; vertical-align: baseline; text-transform: capitalize;}
  .team-terminal-dialog table td {color: #5a5a5a;    word-break: break-all;}
  .team-terminal-dialog table{margin:12px   auto;}
  .table-td-head{text-transform: capitalize; font-weight: 600;}
  .coverage-card{display: grid;
    flex-wrap: wrap;
    grid-column: 3;
    grid-template-columns: 30% 30% 30%;
    column-gap: 14px;
    row-gap: 14px;}
    .release-coverage-card{
      border: 1px solid #80808024;
      padding: 10px;
      border-radius: 7px;
    }
  .release-coverage-card p{margin:4px 0;font-size: 13px;}
  .release-coverage-card p strong{margin: 0; font-size: 13px; text-transform: capitalize; color: #2F7D32;}
  .area-covered-validations .MuiFormHelperText-root{color: #fb2a2a;position: absolute;    bottom: -18px;
    left: 1px;  font-size: 11px;}
  .facilityDownload button{ color: #fff; border-radius: 8px; font-size: 14px; background-color: #2F7D32;}
  .facilityDownload button:hover{color: #fff; background-color: #2F7D32;}
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #2f7d32 !important;
}
